.top-influencer {
  color: white;
  overflow: hidden;
  background: linear-gradient(
      to right,
      rgba(235, 36, 45, 0.8) 0%,
      rgba(161, 57, 139, 0.8) 100%
    ),
    url("../../Assets/Images/Influencer/influencer-background.png");
  padding-top: 50px;
  min-height: 600px;
  .title {
    padding: 0px 10% 0px 10%;
    font-family: var(--nexa-light);
    color: white;
    @media (max-width: 992px) {
      padding: 0 !important;
      font-size: 28px;
    }
  }
  .description {
    padding: 0px 20% 0px 20%;
    font-family: var(--nexa-light);
    @media (max-width: 992px) {
      padding: 0 !important;
      font-size: 14px;
    }
  }
  .influencer-items {
    padding-top: 20px;
    z-index: 1;
    display: inline-flex;
    width: 85%;
    .item {
      background-color: #fff;
      padding: 10px 0px;
      border-radius: 20px;
      font-family: var(--nexa-light);
      .influencer-header {
        margin-bottom: 10px;
        padding: 0 10px;
        .influencer-header-left {
          display: flex;
          img {
            height: 30px;
            width: 30px;
            border-radius: 50%;
            object-fit: cover;
            margin-right: 10px;
          }
          .influencer-detail {
            text-align: left;
            line-height: 0.9;
            .influencer-name {
              font-size: 14px;
              font-weight: 900;
              color: #000;
              margin-bottom: 3px;
              font-family: var(--nexa-bold);

            }
            .influencer-role {
              font-size: 13px;
              color: grey;
            }
          }
        }
        .influencer-header-right {
        }
      }

      .influencer-body {
        img {
          width: 100%;
          height: 200px;
          object-fit: cover;
          object-position: top;
        }
      }
      .influencer-icon-container {
        margin-bottom: -7px;
        padding: 4px 0;
        padding: 4px 10px;

        .influencer-icon-left {
          text-align: left;
          .influencer-icon {
            margin-right: 5px;
            font-size: 18px;
          }
          .heart-icon {
            color: red;
          }
          .message-icon {
            color: #ccc;
          }
          .send-icon {
            color: #ccc;
          }
        }
        .influencer-icon-right {
        }
      }
      .influencer-views {
        color: #000;
        text-align: left;
        font-size: 12px;
        padding: 0 10px;
      }
      .influencer-hashtag {
        color: #000;
        text-align: left;
        font-size: 12px;
        padding: 0 10px;

        .hashtag {
          // background-image: linear-gradient(to right, #eb242d 0%, #a1398b 100%);
          color: var(--brand-color-1);
          background-clip: text;
          background-color: transparent;
          display: inline-flex;
        }
      }
      // margin: 20px 25px 20px 25px;
      // .items-text {
      //   font-family: var(--nexa-light);
      //   font-size: 14px;
      //   line-height: 1.42857143;
      //   margin-bottom: 6px;
      //   margin-top: 6px;
      //   display: inline-block;
      // }
      // img {
      //   border: 4px solid hsl(0, 0%, 88%);
      //   border-radius: 50%;
      // }
      // .items-icon {
      //   i {
      //     padding: 5px;
      //   }
      // }
    }
  }
}
