.header-container {
  // background-color: var(--brand-bg);
  position: relative;
  z-index: 3;
  height: var(--header-height);
  .header-logo {
    height: var(--header-height);
    width: 100%;
    position: relative;
    display: flex;
    align-items: center;
    img {
      position: absolute;
      width: 200px;
      left: -32px;
    }
  }
  .header-right-container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 100%;
    @media (max-width: 992px) {
      display: none;
    }
    .btn-border-gradient {
      span{
        font-size: 16px;
      }
    }
  }
  &::after {
    content: "";
    width: 100%;
    height: 6px;
    left: 0;
    position: absolute;
    background: linear-gradient(
      95deg,
      rgba(238, 58, 57, 1) 0%,
      rgba(150, 58, 149, 1) 33%,
      rgba(238, 58, 57, 1) 66%,
      rgba(150, 58, 149, 1) 100%
    );
    background-size: 400% 400%;
    -webkit-animation: gradientslide 2s linear infinite;
    -moz-animation: gradientslide 2s linear infinite;
    animation: gradientslide 2s linear infinite;
  }
}
