.landing-menu-container {
  min-height: 100vh;
  background-color: black;
  padding: 70px 0;
    background-image: url("https://images.deliveryhero.io/image/fd-bd/LH/u2tb-hero.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
  z-index: 1;
  background-attachment: fixed;
  &::after {
    content: "";
    position: absolute;
    left: 0;
    height: 100%;
    width: 100%;
    top: 0;
    background-color: rgba(0, 0, 0, 0.8);
    z-index: -1;
  }
  .landing-menu-inner-container {
    .landing-menu-header {
      font-family: var(--kanit);
      color: white;
      text-align: center;
    }
    .landing-menu-sub-header {
      font-family: var(--roboto);
      color: #fff;
      text-align: center;
    }

    .landing-menu-main {
      display: flex;
      margin-top: 50px;
      .landing-menu-item-container {
        background-color: rgba(0, 0, 0, 0.5);
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        padding: 30px 0;
        .landing-menu-image {
          width: 100px;
          height: 100px;
          border-radius: 50%;
          overflow: hidden;

          img {
            width: 100%;
            height: 100%;
            border-radius: 50%;
            object-fit: cover;
            object-position: center;
          }
        }
        .landing-menu-name {
            font-family: var(--kanit);
            color: var(--brand-color-1);
            font-size: 22px;
            margin-top: 10px;
        }
      }
    }
  }
}
